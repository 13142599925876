import produce from "immer";
import { IStoreState } from "../initialStoreState";
import { ICTTemplateListState } from "./ictTypes";
import { ICTActions } from ".";
import {
	CLEAR_ICT_TEMPLATES,
	CLEAR_SINGLE_ICT_TEMPLATES,
	FETCH_ICT_TEMPLATES_LIST_FAILED,
	FETCH_ICT_TEMPLATES_LIST_PROGRESS,
	FETCH_ICT_TEMPLATES_LIST_SUCCESS,
	FETCH_ICT_TEMPLATE_FAILED,
	FETCH_ICT_TEMPLATE_PROGRESS,
	FETCH_ICT_TEMPLATE_SUCCESS,
} from "./ictActions";
import { LoadState } from "../../constants/enums";
import { defaultICTTemplateState } from "./ictState";

export const ictReducer = (
	state: IStoreState["ict"] = defaultICTTemplateState,
	action: ICTActions
) => {
	switch (action.type) {
		case FETCH_ICT_TEMPLATES_LIST_PROGRESS: {
			const newState = produce(state, (draftState) => {
				draftState.list.loading = LoadState.InProgress;
				draftState.list.data = [];
			});
			return newState;
		}
		case FETCH_ICT_TEMPLATES_LIST_SUCCESS: {
			const { data, totalRecords } = action.payload;
			const newState = produce(state, (draftState) => {
				draftState.list.loading = LoadState.Loaded;
				draftState.list.data = data;
				draftState.list.totalRecords = totalRecords;
			});
			return newState;
		}
		case FETCH_ICT_TEMPLATES_LIST_FAILED: {
			const newState = produce(state, (draftState) => {
				draftState.list.loading = LoadState.Failed;
				draftState.list.data = [];
			});
			return newState;
		}
		case FETCH_ICT_TEMPLATE_PROGRESS: {
			const newState = produce(state, (draftState) => {
				draftState.single_ict.loading = LoadState.InProgress;
				draftState.single_ict = defaultICTTemplateState["single_ict"];
				draftState.single_ict.error = null;
			});
			return newState;
		}
		case FETCH_ICT_TEMPLATE_SUCCESS: {
			const { data } = action.payload;
			const newState = produce(state, (draftState) => {
				draftState.single_ict.loading = LoadState.Loaded;
				draftState.single_ict.data = data;
				draftState.single_ict.error = null;
			});
			return newState;
		}
		case FETCH_ICT_TEMPLATE_FAILED: {
			const { errorMessage } = action.payload;

			const newState = produce(state, (draftState) => {
				draftState.single_ict.loading = LoadState.InProgress;
				draftState.single_ict = defaultICTTemplateState["single_ict"];
				draftState.single_ict.error = errorMessage;
			});
			return newState;
		}

		case CLEAR_ICT_TEMPLATES: {
			return defaultICTTemplateState;
		}

		case CLEAR_SINGLE_ICT_TEMPLATES: {
			const newState = produce(state, (draftState) => {
				draftState.single_ict = defaultICTTemplateState["single_ict"];
			});
			return newState;
		}
		
		default: {
			return state;
		}
	}
};
