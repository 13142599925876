import { lazy } from "react";
import Loadable from "../../layout/fullLayout/loadable/loadable";
import { MODULE_IDS } from "../../constants/enums";
import { AuthorizedRoute } from "../../router/AuthorizedRoute";
import { PRODUCTS_ROUTE, PRODUCT_TEMPLATES_ROUTE } from "./Product.constants";
import { ProductDetail } from "../detail/Detail";

const ProductsTable = Loadable(
	lazy(() =>
		import("./Products").then(({ Products }) => ({
			default: Products,
		}))
	)
);
const ManageSingleProduct = Loadable(
	lazy(() =>
		import("./ManageSingleProduct").then(({ ManageSingleProduct }) => ({
			default: ManageSingleProduct,
		}))
	)
);

const ProductTemplatesTable = Loadable(
	lazy(() =>
		import("./ProductTemplates/ProductTemplatesTable").then(
			({ ProductTemplatesTable }) => ({
				default: ProductTemplatesTable,
			})
		)
	)
);
const ManageProductTemplate = Loadable(
	lazy(() =>
		import("./ProductTemplates/ManageProductTemplate").then(
			({ ManageProductTemplate }) => ({
				default: ManageProductTemplate,
			})
		)
	)
);
export const productsRoutes = [
	{
		path: PRODUCTS_ROUTE,
		exact: true,
		element: (
			<AuthorizedRoute moduleIds={[MODULE_IDS.PRODUCT]}>
				<ProductsTable />
			</AuthorizedRoute>
		),
	},
	{
		path: `${PRODUCTS_ROUTE}/manage`,
		exact: true,
		element: (
			<AuthorizedRoute moduleIds={[MODULE_IDS.PRODUCT]}>
				<ManageSingleProduct />
			</AuthorizedRoute>
		),
	},
	{
		path: `${PRODUCTS_ROUTE}/manage/:uuid`,
		exact: true,
		element: (
			<AuthorizedRoute moduleIds={[MODULE_IDS.PRODUCT]}>
				<ManageSingleProduct />
			</AuthorizedRoute>
		),
	},

	{
		path: PRODUCT_TEMPLATES_ROUTE,
		exact: true,
		element: (
			<AuthorizedRoute moduleIds={[MODULE_IDS.TEMPLATE]}>
				<ProductTemplatesTable />
			</AuthorizedRoute>
		),
	},
	{
		path: `${PRODUCT_TEMPLATES_ROUTE}/manage`,
		exact: true,
		element: (
			<AuthorizedRoute moduleIds={[MODULE_IDS.TEMPLATE]}>
				<ManageProductTemplate />
			</AuthorizedRoute>
		),
	},
	{
		path: `${PRODUCT_TEMPLATES_ROUTE}/manage/:uuid`,
		exact: true,
		element: (
			<AuthorizedRoute moduleIds={[MODULE_IDS.TEMPLATE]}>
				<ManageProductTemplate />
			</AuthorizedRoute>
		),
	},
	{
		path: "/product-detail",
		exact: true,
		element: (
			<AuthorizedRoute moduleIds={[MODULE_IDS.CONTACT]}>
				<ProductDetail />
			</AuthorizedRoute>
		),
	},
];
