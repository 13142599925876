import { Backdrop, Box, TextField, InputAdornment, CircularProgress, IconButton, TableContainer, Paper, Table, TableBody, TableRow, TableCell, Typography, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { PreviewImage } from '../../../components/PreviewImage/PreviewImage';
import { IProduct } from '../../../redux/products';
import { api } from '../../../api/api';
import SearchIcon from '@mui/icons-material/Search';

interface IProductAutoSearchPanelProps {
    open: boolean
    onClose: () => void
    onSelect: (value: IProduct) => void
}
export const ProductAutoSearchPanel: React.FC<IProductAutoSearchPanelProps> = ({
    open,
    onSelect,
    onClose
}) => {


    const [showProductSearchPanel, setShowProductSearchPanel] = useState<boolean>(false)
    const [textToBeSearched, seTextToBeSearched] = useState<string>("")
    const [productSearchedREsult, seProductSearchedREsult] = useState<IProduct[]>([])
    const [productLoading, setProductLoading] = React.useState(false);

    useEffect(() => {
        searchProductItemsAsync(textToBeSearched)
    }, [textToBeSearched])


    const searchProductItemsAsync = async (value: string) => {
        try {
            setProductLoading(true)
            let searchQuery = "";
            if (value.length > 0) {
                searchQuery = `columns=[product_name]&value=${value}&`;
            }
            const res = await api.get(
                `/product/get-product?${searchQuery}pageNo=1&itemPerPage=10`,
            );
            const finalData: IProduct[] = res.data.data;
            seProductSearchedREsult(finalData);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setProductLoading(false);
        }
    }




    const handleSelectProductItem = (productItem: IProduct) => {
        onSelect(productItem)
        setShowProductSearchPanel(false)
    }



    return (
        <div>
            <Backdrop
                open={open}
                // onClick={() => setShowProductSearchPanel(false)}
                onClick={() => { }}
                sx={(theme) => ({
                    color: '#fff', zIndex: theme.zIndex.drawer + 1,

                })}
            >
                <Box sx={{
                    width: "80%",
                    height: "80vh",
                    background: "#fff",
                    borderRadius: 4

                }} >
                    <TextField
                        variant="outlined"
                        // disabled={disabled}
                        sx={{
                            minHeight: 60,
                            border: "none",
                            width: "100%",
                            '& .MuiOutlinedInput-root': {
                                minHeight: 60,
                                border: "none",
                                borderBottom: "1px solid #757575",
                                borderRadius: "0",
                                fontSize: 24,
                                '&:hover fieldset': {
                                    border: "none",
                                },
                                '&.Mui-focused fieldset': {
                                    border: "none",
                                },
                            },

                            "& .MuiOutlinedInput-notchedOutline": {
                                border: "none",
                            },
                        }}
                        placeholder='Enter product name here '
                        value={textToBeSearched}
                        onChange={(evt) => seTextToBeSearched(evt.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon sx={{ fontSize: 44 }} />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    {productLoading &&
                                        <CircularProgress sx={{ mr: 2 }} />
                                    }
                                    <IconButton
                                        onClick={() => onClose()}
                                        sx={{ border: "1px solid #757575", borderRadius: 2 }}>ESC</IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />

                    {productSearchedREsult.length > 0 ?
                        <TableContainer component={Paper}
                            sx={{
                                height: "inherit",
                                overflowY: "scroll"
                            }}
                        >
                            <Table aria-label="Enquiry Checklist">
                                <TableBody >
                                    {productSearchedREsult.map((product_item, p_index) => {
                                        console.log(`${product_item.line} ==>`, product_item.primary_image_url)
                                        return (
                                            <TableRow key={p_index}>
                                                <TableCell sx={{ width: 100 }}>

                                                    <PreviewImage
                                                        imageUrl={product_item.primary_image_url || ""}
                                                    />
                                                </TableCell>
                                                <TableCell width={250}>
                                                    <Typography variant='h4'>{product_item.line}</Typography>
                                                    <Typography
                                                        component="span"
                                                        sx={{ background: "#dedede", padding: 0.6, borderRadius: 1 }}
                                                        variant='body1'>{product_item.category}</Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant='h4'>{product_item.brand}</Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant='h4'>{product_item.rsp}</Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant='h4'>{product_item.size}</Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Tooltip title="Select Item">
                                                        <IconButton
                                                            onClick={() => handleSelectProductItem(product_item)}
                                                            sx={{ border: "1px solid #757575", borderRadius: 2 }}>Select</IconButton>
                                                    </Tooltip>
                                                </TableCell>

                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>

                        </TableContainer>

                        :
                        <Box
                            sx={{
                                height: "inherit",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <Typography variant='h1' sx={{ color: "#757575" }}>No Result Found...!</Typography>
                        </Box>
                    }
                </Box>
            </Backdrop>

        </div>
    )
}
