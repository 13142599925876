import LinearProgress from "@mui/material/LinearProgress";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { api } from "../../api/api";
import { authAPI } from "../../api/authAPI";
import { ISecurityGroup } from "../../redux/security/security.types";
import { IUser } from "../../redux/UserProfileList/userProfile.types";

export enum userRoles {
	ADMIN = "ADMIN",
	None = "None",
}

export interface IPremissions extends ISecurityGroup {
	view_access: number;
	edit_access: number;
	bulk_export: number;
	bulk_import: number;
	send_mail: number;
	send_sms: number;
	send_whatsapp: number;
	send_call: number;
}

export interface IAuth {
	userInfo: {
		token: string | null;
		isExternalLogin: boolean;
		name: string;
		user: {
			email: string;
			first_name: string;
			last_name: string;
			mobile_no: string;
			role_uuid: string;
			status: string;
			user_uuid: string;
			role_name: string;
			// read only
			role_group: string;
			role_value: string;
		};
		roles: ISecurityGroup[];
	};
	isLogin: boolean;
	login: (
		email: string,
		password: string,
		onSuccess: (isOTPPreview: boolean) => void,
		onError: (message: string) => void
	) => void;
	verifyOTP: (
		otp: string,
		onSuccess: () => void,
		onError: (message: string) => void
	) => void;
	logout: () => void;
	addUserName: (name: string) => void;
	sendForgetPasswordMailAsync: (
		email: string,
		onSuccess: (message: string) => void,
		onError: (message: string) => void
	) => void;
	resetPasswordAsync: (
		email: string,
		password: string,
		otp: number,
		onSuccess: (message: string) => void,
		onError: (message: string) => void
	) => void;
	directLogin: (
		token: string,
		onComplete: (isSuccess: boolean, error?: string) => void
	) => void;
}

const AuthContext = React.createContext<IAuth>({
	userInfo: {
		token: "",
		isExternalLogin: false,
		name: "",
		user: {
			email: "",
			first_name: "",
			last_name: "",
			mobile_no: "",
			role_uuid: "-1",
			status: "ACTIVE",
			user_uuid: "",
			role_name: "",
			role_group: "",
			role_value: "",
		},
		roles: [],
	},
	isLogin: false,
	login: () => {},
	verifyOTP: () => {},
	logout: () => {},
	addUserName: () => {},
	sendForgetPasswordMailAsync: () => {},
	resetPasswordAsync: () => {},
	directLogin: () => {
		return false;
	},
});

export const useAuth = () => React.useContext(AuthContext);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = (
	props
) => {
	const [userInfo, setUserInfo] = React.useState<IAuth["userInfo"]>({
		token: null,
		isExternalLogin: false,
		name: "",
		user: {
			email: "",
			first_name: "",
			last_name: "",
			mobile_no: "",
			role_uuid: "-1",
			status: "",
			user_uuid: "",
			role_name: "",
			role_group: "",
			role_value: "",
		},
		roles: [],
	});
	const [loading, setLoading] = React.useState(true);
	const navigate = useNavigate();
	const location = useLocation();

	React.useEffect(() => {
		const auth = localStorage.getItem("auth");
		const key = new URLSearchParams(location.search).get("key");
		if (key) {
			localStorage.clear();
			setLoading(false);
			return;
		}
		if (auth) {
			const data = JSON.parse(auth);
			const date1 = new Date(data.date);
			const date2 = new Date();
			const diff = date2.getHours() - date1.getHours();
			if (diff <= 1) {
				setUserInfo(data);
			}
		}
		setLoading(false);
	}, []);

	const handleLogin = async (
		email: string,
		password: string,
		onSuccess: (isOTPPreview: boolean) => void,
		onError: (message: string) => void
	) => {
		try {
			const res = await api.post(authAPI.login, {
				email: email,
				password: password,
			});
			if (res.data.data.isOTPPreview) {
				setUserInfo({
					...userInfo,
					user: {
						...userInfo.user,
						email: email,
					},
				});
			} else {
				const userData: IAuth["userInfo"] = res.data.data;
				userData.name = "";
				if (userData.user.first_name) {
					userData.name = userData.user.first_name;
				}
				if (userData.user.last_name) {
					userData.name += " " + userData.user.last_name;
				}
				setUserInfo(userData);
				localStorage.setItem(
					"auth",
					JSON.stringify({ ...userData, date: new Date() })
				);
			}

			onSuccess(res.data.data.isOTPPreview);
		} catch (error: any) {
			onError(error.response.data.message);
		}
	};

	const handleVerfiyOTP = async (
		otp: string,
		onSuccess: () => void,
		onError: (message: string) => void
	) => {
		try {
			const res = await api.post(authAPI.validateOtpGetToken, {
				email: userInfo.user.email,
				otp: Number(otp),
			});
			const data = res.data.data;
			const userData: IAuth["userInfo"] = {
				...userInfo,
				name: "",
				token: data.token,
				user: data.user,
			};

			userData.name = "";
			if (data.user.first_name) {
				userData.name = data.user.first_name;
			}
			if (userData.user.last_name) {
				userData.name += " " + data.user.last_name;
			}
			setUserInfo(userData);
			localStorage.setItem(
				"auth",
				JSON.stringify({ ...userData, date: new Date() })
			);
			onSuccess();
		} catch (error: any) {
			onError(error.response.data.message);
		}
	};

	const handleDirectLogin = async (
		token: string,
		onComplete: (isSuccess: boolean, error?: string) => void
	) => {
		const userData: IAuth["userInfo"] = {
			...userInfo,
		};

		try {
			const res = await api.get(authAPI.externalSupplierUserDetails, {
				headers: {
					"external-auth-Token": token,
				},
			});
			const data: IUser[] = res.data.data;
			if (data.length > 0) {
				const userInfo = data[0];
				userData.isExternalLogin = true;
				userData.token = token;
				userData.name = userInfo.full_name + " " + userInfo.last_name || "";
				userData.user = {
					email: userInfo.email as string,
					first_name: userInfo.first_name,
					last_name: userInfo.last_name || "",
					mobile_no: userInfo.mobile || "",
					role_value: userInfo.role_value || "",
					status: "",
					user_uuid: userInfo.user_uuid || "",
					role_name: userInfo.role_value || "",
					role_group: "",
					role_uuid: userInfo.role_uuid,
				};
				userData.roles = userInfo.module_security as any;
				setUserInfo(userData);
				localStorage.setItem(
					"auth",
					JSON.stringify({ ...userData, date: new Date() })
				);
				onComplete(true);
			} else {
				onComplete(false, "No User Found");
			}
			onComplete(true);
		} catch (error: any) {
			onComplete(false, error.message);
		}

		// setUserInfo(userData);
		// localStorage.setItem(
		//   "auth",
		//   JSON.stringify({ ...userData, date: new Date() })
		// );
	};

	const handleLogout = () => {
		localStorage.clear();
		setUserInfo({
			token: "",
			isExternalLogin: false,
			name: "",
			user: {
				email: "",
				first_name: "",
				last_name: "",
				mobile_no: "",
				role_uuid: "",
				status: "",
				user_uuid: "",
				role_name: "",
				role_group: "",
				role_value: "",
			},
			roles: [],
		});
		window.open("/auth/login", "_self");
	};

	const handleForgetPassword = (
		email: string,
		onSuccess: (message: string) => void,
		onError: (message: string) => void
	) => {
		api
			.post(authAPI.forgetPassword, {
				email: email,
				otp: 0,
				user_password: "",
				action: "SEND",
			})
			.then((response) => {
				onSuccess(response.data.message);
			})
			.catch((error) => {
				onError(error.response.data.message);
			});
	};

	const resetPasswordAsync = (
		email: string,
		password: string,
		otp: number,
		onSuccess: (message: string) => void,
		onError: (message: string) => void
	) => {
		api
			.post(authAPI.forgetPassword, {
				email: email,
				otp: otp,
				user_password: password,
				action: "",
			})
			.then((response) => {
				onSuccess(response.data.message);
			})
			.catch((error) => {
				onError(error.response.data.message);
			});
	};

	const addUserName = (name: string) => {
		setUserInfo({ ...userInfo, name: name });
	};

	if (loading) {
		return <LinearProgress />;
	}

	return (
		<AuthContext.Provider
			value={{
				userInfo: userInfo,
				isLogin: userInfo.token ? true : false,
				login: handleLogin,
				verifyOTP: handleVerfiyOTP,
				logout: handleLogout,
				addUserName: addUserName,
				sendForgetPasswordMailAsync: handleForgetPassword,
				resetPasswordAsync: resetPasswordAsync,
				directLogin: handleDirectLogin,
			}}
		>
			{props.children}
		</AuthContext.Provider>
	);
};
