import { lazy } from "react";
import Loadable from "../../layout/fullLayout/loadable/loadable";
import { MODULE_IDS } from "../../constants/enums";
import { AuthorizedRoute } from "../../router/AuthorizedRoute";
import { APP_PRODUCT_DOCUMENTS_ROUTE } from "./ProductDocuments.constants";

const ProductDocumentsTable = Loadable(
	lazy(() =>
		import("./ProductDocumentsTable").then(({ ProductDocumentsTable }) => ({
			default: ProductDocumentsTable,
		}))
	)
);
export const productDocumentsRoutes = [
	{
		path: APP_PRODUCT_DOCUMENTS_ROUTE,
		exact: true,
		element: (
			<AuthorizedRoute moduleIds={[MODULE_IDS.PRODUCT_AND_PROCESS]}>
				<ProductDocumentsTable />
			</AuthorizedRoute>
		),
	},
	//   {
	//     path: `${PRODUCTS_ROUTE}/manage`,
	//     exact: true,
	//     element: (
	//       <AuthorizedRoute allowFullAccess moduleIds={[MODULE_IDS.PRODUCTS]}>
	//         <ManageSingleProduct />
	//       </AuthorizedRoute>
	//     ),
	//   },
	//   {
	//     path: `${PRODUCTS_ROUTE}/manage/:uuid`,
	//     exact: true,
	//     element: (
	//       <AuthorizedRoute allowFullAccess moduleIds={[MODULE_IDS.PRODUCTS]}>
	//         <ManageSingleProduct />
	//       </AuthorizedRoute>
	//     ),
	//   },
];
