import { lazy } from "react";
import Loadable from "../../../layout/fullLayout/loadable/loadable";
import { MODULE_IDS } from "../../../constants/enums";
import { AuthorizedRoute } from "../../../router/AuthorizedRoute";
import { APP_SUPPLIER_BRANCHES_ROUTE } from "./SuppliersBranch.constants";

const SuppliersBranchListTable = Loadable(
	lazy(() =>
		import("./SuppliersBranchListTable").then(
			({ SuppliersBranchListTable }) => ({
				default: SuppliersBranchListTable,
			})
		)
	)
);

const ManageSupplierBranch = Loadable(
	lazy(() =>
		import("./ManageSupplierBranch").then(({ ManageSupplierBranch }) => ({
			default: ManageSupplierBranch,
		}))
	)
);

export const supplierBranchesRoutes = [
	{
		path: APP_SUPPLIER_BRANCHES_ROUTE,
		exact: true,
		element: (
			<AuthorizedRoute moduleIds={[MODULE_IDS.USERS]}>
				<SuppliersBranchListTable />
			</AuthorizedRoute>
		),
	},
	{
		path: `${APP_SUPPLIER_BRANCHES_ROUTE}/manage`,
		exact: true,
		element: (
			<AuthorizedRoute moduleIds={[MODULE_IDS.USERS]}>
				<ManageSupplierBranch />
			</AuthorizedRoute>
		),
	},
	{
		path: `${APP_SUPPLIER_BRANCHES_ROUTE}/manage/:uuid`,
		exact: true,
		element: (
			<AuthorizedRoute moduleIds={[MODULE_IDS.USERS]}>
				<ManageSupplierBranch />
			</AuthorizedRoute>
		),
	},
];
