import { lazy } from "react";
import { Navigate } from "react-router-dom";
import { MODULE_IDS } from "../constants/enums";
import Loadable from "../layout/fullLayout/loadable/loadable";
import { BeforeProfile } from "../views/Profile/UsersList/UsersList";
import { ForgetPassword } from "../views/authentication/ForgetPassword/ForgetPassword";
import { OOPSError } from "../views/authentication/OopsError";

import { productsRoutes } from "../views/products/Products.router";
import { supplier_module_routes } from "../views/supplier";
import { templatesRoutes } from "../views/template/Templates.router";
import { AuthorizedRoute } from "./AuthorizedRoute";
import { ProtectedRoute } from "./ProtectedRoutes";
import { PublicRoute } from "./PublicRoute";
import { fileUploadWithAIRouter } from "../views/FileUploadWithAI/ImportLineSheet.router";
import { ordersRoutes } from "../views/Orders/Orders.router";
import { externalSupplierOrdersRoutes } from "../views/ExternalSupplier/ExternalSuplier.router";
import { ManageExternalSupplierOrder } from "../views/ExternalSupplier/ExternalSupplierOrders/ManageExternalSupplierOrder";
import { approvalsRoutes } from "../views/approval/Approvals.router";
import { productDocumentsRoutes } from "../views/ProductDocuments/ProductDocuments.router";
import { productValidationRoutes } from "../views/ProductValidation/ProductValidation.router";
import { TopUpDialog } from "../views/budget/dialogs/TopUpDialog";
import { LedgerBudgetList } from "../views/budget/LedgerBudgetList";
import { ManageGuidlinesRoutes } from "../views/guidlines/guidlines.router";
import { discrepancyRoutes } from "../views/discrepancy/discrepancy.router";
import { IctFileUploadWithAIRouter } from "../views/Ict/Ict.router";

/* ***Layouts**** */
const FullLayout = Loadable(
	lazy(() =>
		import("../layout/fullLayout/FullLayout").then(({ FullLayout }) => ({
			default: FullLayout,
		}))
	)
);
const BlankLayout = Loadable(
	lazy(() => import("../layout/blankLayout/BlankLayout"))
);
/* ***End Layouts**** */

const Error = Loadable(
	lazy(() =>
		import("../views/authentication/Error").then(({ Error }) => ({
			default: Error,
		}))
	)
);

/* ****Pages***** */

const Dashboard = Loadable(
	lazy(() =>
		import("../views/dashboard/Dashboard/Dashbaord").then(({ Dashboard }) => ({
			default: Dashboard,
		}))
	)
);

const Login = Loadable(
	lazy(() =>
		import("../views/authentication/Login").then(({ Login }) => ({
			default: Login,
		}))
	)
);
const ExternalSupplierAuth = Loadable(
	lazy(() =>
		import(
			"../views/supplier/Suppliers/ExternalSupplier/ExternalSupplierAuth"
		).then(({ ExternalSupplierAuth }) => ({
			default: ExternalSupplierAuth,
		}))
	)
);
const AccessDenied = Loadable(
	lazy(() =>
		import("../views/authentication/AccessDenied").then(({ AccessDenied }) => ({
			default: AccessDenied,
		}))
	)
);

const ManageUser = Loadable(
	lazy(() =>
		import("../views/Profile/UsersList/ManageUser").then(({ ManageUser }) => ({
			default: ManageUser,
		}))
	)
);

const ViewProfile = Loadable(
	lazy(() =>
		import("../views/Profile/ViewProfile/ViewProfile").then(
			({ ViewProfile }) => ({
				default: ViewProfile,
			})
		)
	)
);

const SecurityDashboard = Loadable(
	lazy(() =>
		import("../views/Security/CreateSecurityGroup").then(
			({ CreateSecurityGroup }) => ({ default: CreateSecurityGroup })
		)
	)
);

const SecurityApprovalsList = Loadable(
	lazy(() =>
		import("../views/Security/approval/ApprovalList").then(
			({ ApprovalList }) => ({ default: ApprovalList })
		)
	)
);

const ManageSecuirtyApproval = Loadable(
	lazy(() =>
		import("../views/Security/approval/ManageApproval").then(
			({ ManageApproval }) => ({ default: ManageApproval })
		)
	)
);

const ListSecurityGroups = Loadable(
	lazy(() =>
		import("../views/Security/ListSecurityGroups").then(
			({ ListSecurityGroups }) => ({ default: ListSecurityGroups })
		)
	)
);

const RoleGroupTable = Loadable(
	lazy(() =>
		import("../views/Security/roleGroup/RoleGroupTable").then(
			({ RoleGroupTable }) => ({ default: RoleGroupTable })
		)
	)
);

const QuotesInvoice = Loadable(
	lazy(() =>
		import("../publicModules/QuotesInvoice/QuotesInvoice").then(
			({ QuotesInvoice }) => ({
				default: QuotesInvoice,
			})
		)
	)
);

//***************** budget ************************** */

const BudgetList = Loadable(
	lazy(() =>
		import("../views/budget/BudgetList").then(({ BudgetList }) => ({
			default: BudgetList,
		}))
	)
);

const ManageBudget = Loadable(
	lazy(() =>
		import("../views/budget/ManageBudget").then(({ ManageBudget }) => ({
			default: ManageBudget,
		}))
	)
);

/************************ Approval ************************/

const ApprovalQuoteList = Loadable(
	lazy(() =>
		import("../views/approval/quote/ApprovalQuoteList").then(
			({ ApprovalQuotesList }) => ({
				default: ApprovalQuotesList,
			})
		)
	)
);

const ItemApprovalList = Loadable(
	lazy(() =>
		import("../views/approval/itemApprovals/ItemApprovalList").then(
			({ ItemApprovalList }) => ({
				default: ItemApprovalList,
			})
		)
	)
);
const ApprovalOrderRequest = Loadable(
	lazy(() =>
		import("../views/approval/approvalOrderRequest/ApprovalOrderRequest").then(
			({ ApprovalOrderRequest }) => ({
				default: ApprovalOrderRequest,
			})
		)
	)
);

// *********************** Ai Pdf Routes ************************/

export const Router = [
	{
		path: "/",

		element: (
			<ProtectedRoute>
				<FullLayout />
			</ProtectedRoute>
		),
		children: [
			{
				path: "/",
				exact: true,
				element: (
					<Navigate
						to="/dashboard"
						replace
					/>
				),
			},
			{
				path: "/dashboard",
				exact: true,
				element: (
					<AuthorizedRoute
						allowFullAccess
						moduleIds={[]}
					>
						<Dashboard />
					</AuthorizedRoute>
				),
			},
			// **************** ai pdf upload routes **********************

			{
				path: "/view-profile",
				exact: true,
				element: (
					<AuthorizedRoute moduleIds={[MODULE_IDS.USERS]}>
						<ViewProfile />
					</AuthorizedRoute>
				),
			},
			{
				path: "/users/manage",
				exact: true,
				element: (
					<AuthorizedRoute moduleIds={[MODULE_IDS.USERS]}>
						<ManageUser />
					</AuthorizedRoute>
				),
			},
			{
				path: "/users",
				exact: true,
				element: (
					<AuthorizedRoute moduleIds={[MODULE_IDS.USERS]}>
						<BeforeProfile />
					</AuthorizedRoute>
				),
			},
			{
				path: "/budget",
				exact: true,
				element: (
					<AuthorizedRoute moduleIds={[MODULE_IDS.BUDGET]}>
						<BudgetList />
					</AuthorizedRoute>
				),
			},
			{
				path: "/budget/manage/:user_uuid",
				exact: true,
				element: (
					<AuthorizedRoute moduleIds={[MODULE_IDS.BUDGET]}>
						<ManageBudget />
					</AuthorizedRoute>
				),
			},
			{
				path: "/budget/manage",
				exact: true,
				element: (
					<AuthorizedRoute moduleIds={[MODULE_IDS.BUDGET]}>
						<ManageBudget />
					</AuthorizedRoute>
				),
			},
			{
				path: "/budget/ledger",
				exact: true,
				element: (
					<AuthorizedRoute moduleIds={[MODULE_IDS.BUDGET_LEDGER]}>
						<LedgerBudgetList />
					</AuthorizedRoute>
				),
			},

			{
				path: "/users/manage/:userId",
				exact: true,
				element: (
					<AuthorizedRoute moduleIds={[MODULE_IDS.USERS]}>
						<ManageUser isMyProfile={false} />
					</AuthorizedRoute>
				),
			},
			{
				path: "/my-profile",
				exact: true,
				element: (
					<AuthorizedRoute moduleIds={[]}>
						<ManageUser isMyProfile={true} />
					</AuthorizedRoute>
				),
			},
			{
				path: "/list-security-groups",
				exact: true,
				element: (
					<AuthorizedRoute moduleIds={[MODULE_IDS.SECURITY]}>
						<ListSecurityGroups />
					</AuthorizedRoute>
				),
			},
			{
				path: "/create-security-group",
				exact: true,
				element: (
					<AuthorizedRoute moduleIds={[MODULE_IDS.SECURITY]}>
						<SecurityDashboard />
					</AuthorizedRoute>
				),
			},
			{
				path: "/create-security-group/:roleId",
				exact: true,
				element: (
					<AuthorizedRoute moduleIds={[MODULE_IDS.SECURITY]}>
						<SecurityDashboard />
					</AuthorizedRoute>
				),
			},
			{
				path: "/secuirty-role-duplicate/:roleId",
				exact: true,
				element: (
					<AuthorizedRoute moduleIds={[MODULE_IDS.SECURITY]}>
						<SecurityDashboard isDuplicate />
					</AuthorizedRoute>
				),
			},
			{
				path: "/security/approvals-list",
				exact: true,
				element: (
					<AuthorizedRoute moduleIds={[MODULE_IDS.SECURITY]}>
						<SecurityApprovalsList />
					</AuthorizedRoute>
				),
			},
			{
				path: "/security/manage-approval",
				exact: true,
				element: (
					<AuthorizedRoute moduleIds={[MODULE_IDS.SECURITY]}>
						<ManageSecuirtyApproval />
					</AuthorizedRoute>
				),
			},
			{
				path: "/security/manage-approval/:approvalId",
				exact: true,
				element: (
					<AuthorizedRoute moduleIds={[MODULE_IDS.SECURITY]}>
						<ManageSecuirtyApproval />
					</AuthorizedRoute>
				),
			},
			{
				path: "/security/role-groups",
				exact: true,
				element: (
					<AuthorizedRoute moduleIds={[MODULE_IDS.ROLE_GROUP]}>
						<RoleGroupTable />
					</AuthorizedRoute>
				),
			},
			/************************Approval ************************/
			{
				path: "/approval/quote",
				exact: true,
				element: (
					<AuthorizedRoute moduleIds={[MODULE_IDS.USERS]}>
						<ApprovalQuoteList />
					</AuthorizedRoute>
				),
			},
			{
				path: "/approval/item",
				exact: true,
				element: (
					<AuthorizedRoute moduleIds={[MODULE_IDS.USERS]}>
						<ItemApprovalList />
					</AuthorizedRoute>
				),
			},
			{
				path: "/approval/order-request",
				exact: true,
				element: (
					<AuthorizedRoute moduleIds={[MODULE_IDS.USERS]}>
						<ApprovalOrderRequest />
					</AuthorizedRoute>
				),
			},

			{ path: "access", element: <AccessDenied /> },
			{ path: "error", element: <OOPSError /> },
			{ path: "*", element: <Navigate to="/auth/404" /> },
			{ path: "/", element: <Navigate to="/dashboard" /> },
		]
			.concat(productsRoutes)
			.concat(templatesRoutes)
			.concat(supplier_module_routes)
			.concat(fileUploadWithAIRouter)
			.concat(ordersRoutes)
			.concat(approvalsRoutes)
			.concat(productDocumentsRoutes)
			.concat(productValidationRoutes)
			.concat(ManageGuidlinesRoutes)
			.concat(discrepancyRoutes)
			.concat(IctFileUploadWithAIRouter),
	},

	// --------------------------------TASK ROUTING----------------------------------------------

	{
		path: "/auth",
		element: (
			<PublicRoute>
				<BlankLayout />
			</PublicRoute>
		),
		children: [
			{ path: "404", element: <Error /> },
			{ path: "login", element: <Login /> },
			{ path: "forget-password", element: <ForgetPassword /> },
			{ path: "*", element: <Navigate to="/auth/404" /> },
		],
	},
	{
		path: "/supplier",
		element: <BlankLayout />,
		children: [
			{ path: "404", element: <Error /> },
			{ path: "order", element: <ExternalSupplierAuth /> },
			{ path: "*", element: <Navigate to="/auth/404" /> },
		],
	},
	{
		path: "/public/quotes/:quoteId",
		element: <QuotesInvoice />,
	},

	// {
	//   path: "/external-supplier",
	//   element: <PublicRoute><BlankLayout /></PublicRoute>,
	//   children: externalSupplierOrdersRoutes,
	// },
	// {
	//   path: "/external-supplier/order",
	//   element: <ManageExternalSupplierOrder />,
	// },
];
