import { lazy } from "react";
import Loadable from "../../layout/fullLayout/loadable/loadable";
import { MODULE_IDS } from "../../constants/enums";
import { AuthorizedRoute } from "../../router/AuthorizedRoute";
import { APP_APPROVALS_ORDERS_ROUTE } from "./Approvals.constants";

const OrdersApprovalsListTable = Loadable(
	lazy(() =>
		import("./OrderApprovals/OrdersApprovalsListTable").then(
			({ OrdersApprovalsListTable }) => ({
				default: OrdersApprovalsListTable,
			})
		)
	)
);

export const approvalsRoutes = [
	{
		path: APP_APPROVALS_ORDERS_ROUTE,
		exact: true,
		element: (
			<AuthorizedRoute moduleIds={[MODULE_IDS.ORDER]}>
				<OrdersApprovalsListTable />
			</AuthorizedRoute>
		),
	},
];
