import React from "react";
import { Dialog } from "../../../components/Dialogs/Dialog";
import { Grid } from "@mui/material";
import { TextFieldWithLabel } from "../../../components/TextFieldWithLabel/TextFieldWithLabel";
import {
	ControlledCustomSelect,
	CustomFormLabel,
} from "../../../components/formsComponents";
import { available_active_status_list } from "../../../constants/constants";
import { StandardCard } from "../../../components/Cards";
import { ImageUpload } from "../../../components/ImageUpload/ImageUpload";
import { IOrderItem } from "../../../redux/orders";
import { PreviewImage } from "../../../components/PreviewImage/PreviewImage";

interface IViewSingleOrderItemDialogProps {
	open: boolean;
	productInfo: IOrderItem;
	onClose: () => void;
}
export const ViewSingleOrderItemDialog: React.FC<
	IViewSingleOrderItemDialogProps
> = ({ open, productInfo, onClose }) => {
	return (
		<Dialog
			fullScreen
			open={open}
			title="Product Information"
			onClose={onClose}
		>
			<Grid
				container
				spacing={2}
				marginTop={0.5}
			>
				<Grid
					item
					xs={12}
					md={3}
				>
					<StandardCard
						sx={{
							height: "100%",
							display: "flex",
						}}
					>
						<PreviewImage imageUrl={productInfo.preview_url ?? null} />
					</StandardCard>
				</Grid>
				<Grid
					item
					xs={12}
					md={9}
				>
					<StandardCard heading="Item Information">
						<Grid
							container
							spacing={1}
						>
							<Grid
								item
								xs={12}
								lg={6}
							>
								<TextFieldWithLabel
									heading={"Item Name"}
									type="text"
									id="line"
									fullWidth
									disabled
									value={productInfo.line}
								/>
							</Grid>
							<Grid
								item
								xs={12}
								lg={6}
							>
								<TextFieldWithLabel
									heading={"Size"}
									type="text"
									id="size"
									fullWidth
									disabled
									value={productInfo.size}
								/>
							</Grid>
							<Grid
								item
								xs={12}
								lg={6}
							>
								<TextFieldWithLabel
									heading={"Brand"}
									type="text"
									id="brand"
									fullWidth
									disabled
									value={productInfo.brand}
								/>
							</Grid>
							<Grid
								item
								xs={12}
								lg={6}
							>
								<TextFieldWithLabel
									heading={"Category"}
									type="text"
									id="category"
									fullWidth
									disabled
									value={productInfo.category}
								/>
							</Grid>
							<Grid
								item
								xs={12}
								lg={6}
							>
								<TextFieldWithLabel
									heading={"Price"}
									type="text"
									id="rsp"
									fullWidth
									disabled
									value={productInfo.rsp}
									//// onChange={(e) => handleProductChange(index, "purchase_price", e.target.value)}
								/>
							</Grid>

							<Grid
								item
								xs={12}
								lg={6}
							>
								<TextFieldWithLabel
									heading={"Unit Cost"}
									type="text"
									id="unit_cost"
									fullWidth
									disabled
									value={productInfo.unit_cost}
									// onChange={(e) => handleProductChange(index, "retail_price", e.target.value)}
								/>
							</Grid>
						</Grid>
					</StandardCard>
				</Grid>
			</Grid>

			<Grid
				container
				spacing={1}
			>
				<Grid
					item
					xs={12}
					lg={4}
				>
					<TextFieldWithLabel
						heading={"Product Vpn"}
						type="text"
						id="product_vpn"
						fullWidth
						disabled
						value={productInfo.product_vpn}
					/>
				</Grid>
				<Grid
					item
					xs={12}
					lg={4}
				>
					<TextFieldWithLabel
						heading={"Style"}
						type="text"
						id="style"
						fullWidth
						disabled
						value={productInfo.style}
					/>
				</Grid>
				<Grid
					item
					xs={12}
					lg={4}
				>
					<TextFieldWithLabel
						heading={"Gender"}
						type="text"
						id="gender"
						fullWidth
						disabled
						value={productInfo.gender}
					/>
				</Grid>

				<Grid
					item
					xs={12}
					lg={4}
				>
					<TextFieldWithLabel
						heading={"Fabric"}
						type="text"
						id="fabric"
						fullWidth
						disabled
						value={productInfo.fabric}
					/>
				</Grid>
				<Grid
					item
					xs={12}
					lg={4}
				>
					<TextFieldWithLabel
						heading={"Subclass"}
						type="text"
						id="subclass"
						fullWidth
						disabled
						value={productInfo.subclass}
					/>
				</Grid>
				<Grid
					item
					xs={12}
					lg={4}
				>
					<TextFieldWithLabel
						heading={"season"}
						type="text"
						id="season"
						fullWidth
						disabled
						value={productInfo.season}
					/>
				</Grid>
				<Grid
					item
					xs={12}
					lg={4}
				>
					<TextFieldWithLabel
						heading={"Color"}
						type="text"
						id="color"
						fullWidth
						disabled
						value={productInfo.color}
					/>
				</Grid>
				<Grid
					item
					xs={12}
					lg={4}
				>
					<TextFieldWithLabel
						heading={"currency"}
						type="text"
						id="currency"
						fullWidth
						disabled
						value={productInfo.currency}
					/>
				</Grid>

				<Grid
					item
					xs={12}
					lg={4}
				>
					<TextFieldWithLabel
						heading={"Quantity"}
						type="text"
						id="quantity"
						fullWidth
						disabled
						value={productInfo.quantity}
					/>
				</Grid>
				{/* <Grid
					item
					xs={12}
					lg={4}
				>
					<TextFieldWithLabel
						heading={"Usages Specificity"}
						type="text"
						id="usage_specificity"
						fullWidth
						disabled
						value={productInfo.usage_specificity}
						// onChange={(e) => handleProductChange(index, "usage_specificity", e.target.value)}
					/>
				</Grid> */}

				<Grid
					item
					xs={12}
					lg={4}
				>
					<TextFieldWithLabel
						heading={"Season"}
						type="text"
						id="season"
						fullWidth
						disabled
						value={productInfo.season}
						// onChange={(e) => handleProductChange(index, "season", e.target.value)}
					/>
				</Grid>
				<Grid
					item
					xs={12}
					lg={4}
				>
					<TextFieldWithLabel
						heading={"Currency"}
						type="text"
						id="currency"
						fullWidth
						disabled
						value={productInfo.currency}
						// onChange={(e) => handleProductChange(index, "currency", e.target.value)}
					/>
				</Grid>

				<Grid
					item
					xs={12}
					lg={4}
				>
					<CustomFormLabel>Status</CustomFormLabel>
					<ControlledCustomSelect
						name="status"
						fullWidth
						disabled
						value={productInfo.status}
						options={available_active_status_list}
						// displayEmpty
						// placeholder="Select one"
					/>
				</Grid>
			</Grid>
		</Dialog>
	);
};
