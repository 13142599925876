/* eslint-disable react-hooks/exhaustive-deps */
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { debounce } from "lodash";
import React from "react";
import { api } from "../../api/api";
import { CustomFormLabel, CustomTextField } from "../formsComponents";
import { IUser, IUsersAutoSearch } from "./AutoCompleteSearches.types";
const INITIAL_STATE: IUser = {
	full_name: "",
	first_name: "",
	personal_email: "",
	user_uuid: "",
	last_name: null,
	job_title: null,
	manager_uuid: null,
	user_type: null,
	assigned_phone_number: null,
	shared_email: null,
	mobile: null,
	home_phone: null,
	linkedin_profile: null,
	hire_date: null,
	last_day_at_work: null,
	branch: null,
	department: null,
	fax: null,
	date_of_birth: null,
	mother_maiden_name: null,
	photo: null,
	signature: null,
	street_address: null,
	unit_or_suite: null,
	city: null,
	province_or_state: null,
	postal_code: null,
	country: null,
	languages_known: null,
	documents: null,
	status: "ACTIVE",
	user_name: "",
	legal_entity: null,
};

export const UsersAutoSearch: React.FC<IUsersAutoSearch> = (props) => {
	const {
		uniqueKey,
		label,
		column,
		value,
		onSelect,
		disabled,
		error,
		roleGroup,
		hideAddButton,
	} = props;
	const [options, setOptions] = React.useState<IUser[]>([]);
	const [loading, setLoading] = React.useState(false);
	const [search, setSearchText] = React.useState<any>("");
	const [openUserDialog, setOpenUserDialog] = React.useState(false);

	const fetchSuggestion = async (enteredValue: string) => {
		// console.log("fetchSuggestion options ", options);
		setLoading(true);
		try {
			let searchQuery = "&";
			if (roleGroup) {
				searchQuery += `&role_group=${roleGroup}&`;
			}
			if (enteredValue.length > 0) {
				searchQuery = `&columns=first_name,last_name&value=${enteredValue}&`;
			}

			console.log("userAutoSearch");
			const res = await api.get(
				`/user/get-user?role_value=ADMIN&status=ACTIVE${searchQuery}pageNo=1&itemPerPage=20`
			);

			const data: IUser[] = res.data.data;
			console.log("data", data);
			const finalData: IUser[] = [];
			for (const user of data) {
				finalData.push(user);
			}
			if (value?.user_uuid?.length > 0) {
				const option: IUser = {
					...INITIAL_STATE,
					user_uuid: value.user_uuid,
					first_name: value.first_name,
				};
				finalData.push(option);
			}
			setOptions(finalData);
			setLoading(false);
		} catch (error) {
			console.error("Error fetching data:", error);
			setLoading(false);
		} finally {
			setLoading(false);
		}
	};
	const debounceFn = React.useCallback(debounce(fetchSuggestion, 800), []);

	const getOptionLabel = (option: IUser | string) => {
		if (typeof option === "string") return option || "";
		// if (!option || typeof option !== "object") return ""; // Fallback for invalid options
		return `${option.first_name}`;
	};

	const getValue = () => {
		return (
			options.find((option) => option.user_uuid === value.user_uuid) || null
		);
	};

	const handleToggle = () => {
		setOpenUserDialog(!openUserDialog);
	};

	const handleComplete = (user: IUser) => {
		setOptions([user, ...options]);
		onSelect(user);
		handleToggle();
	};

	const handleDropDownOnFocus = () => {
		debounceFn("");
	};

	React.useEffect(() => {
		if (search && search !== value.first_name) {
			debounceFn(search);
		}
	}, [search]);

	React.useEffect(() => {
		if (value && typeof value === "object" && value?.user_uuid?.length > 0) {
			const option: IUser = {
				...INITIAL_STATE,
				user_uuid: value.user_uuid,
				first_name: value.first_name,
			};
			setOptions([option]);
		}
	}, [value]);
	// console.log("Page data ===>", options);
	return (
		<>
			{label && (
				<Box
					display="flex"
					justifyContent={"flex-start"}
					alignItems="center"
				>
					<CustomFormLabel display={"block"}>{` ${label}  `}</CustomFormLabel>
					{!hideAddButton &&
						search.length > 0 &&
						options.length === 0 &&
						!loading && (
							<Box
								sx={{
									paddingLeft: 1,
									display: "flex",
									alignItems: "center",
									justifyContent: "flex-start",
									marginTop: 1,
								}}
								onClick={handleToggle}
							>
								<AddCircleIcon
									fontSize="small"
									color="primary"
								/>
								<Typography
									variant="h6"
									color="primary"
									marginLeft={0.5}
									marginTop={0.3}
									fontWeight={500}
								>
									Add User
								</Typography>
							</Box>
						)}
				</Box>
			)}
			<Stack
				direction={"row"}
				justifyContent={"space-between"}
				spacing={1}
			>
				<Autocomplete
					id={uniqueKey}
					fullWidth
					freeSolo
					disabled={disabled}
					sx={{
						".MuiOutlinedInput-root": {
							paddingTop: "2px",
							paddingBottom: "2px",
							fontSize: "0.8rem",
							color: "rgb(38, 38, 38)",
							width: "100%",
							backgroundColor: disabled ? "#f8f9fb" : "inherit",
						},
					}}
					getOptionLabel={getOptionLabel}
					isOptionEqualToValue={(option, value) =>
						typeof option === "string"
							? option === value
							: option.first_name === value.first_name
					}
					filterOptions={(x) => x}
					options={options}
					autoComplete
					includeInputInList
					value={getValue()}
					loading={loading}
					noOptionsText="No User"
					onFocus={handleDropDownOnFocus}
					//@ts-ignore
					onChange={(
						event: React.ChangeEvent<HTMLElement>,
						newValue: IUser | null
					) => {
						if (newValue) {
							onSelect(newValue);
						} else {
							onSelect(INITIAL_STATE);
						}
					}}
					onInputChange={(event, newInputValue) => {
						if ((event && event.type === "change") || !newInputValue) {
							setSearchText(newInputValue);
						}
						if (!newInputValue) {
							onSelect(INITIAL_STATE);
						}
					}}
					renderInput={(params) => (
						<CustomTextField
							{...params}
							fullWidth
							InputProps={{
								...params.InputProps,
								endAdornment: (
									<React.Fragment>
										{loading && (
											<CircularProgress
												color="inherit"
												size={20}
											/>
										)}
										{params.InputProps.endAdornment}
									</React.Fragment>
								),
							}}
							disabled={disabled}
							error={error ? true : false}
							helperText={error}
						/>
					)}
				/>
				{/* <Box>
          <Tooltip title="Create Customer">
            <Fab
              size="small"
              color="primary"
              aria-label="add"
              onClick={handleToggle}
            >
              <AddIcon />
            </Fab>
          </Tooltip>
        </Box> */}
			</Stack>
			{/* {openCustomer && (
        <ManageCustomer
          open={openCustomer}
          company={INITIAL_STATE}
          onClose={handleToggle}
          onComplete={handleComplete}
        />
      )} */}
		</>
	);
};
