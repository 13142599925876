import React from "react";
import { ISecurityGroup } from "../../redux/security/security.types";
import { useAuth } from "../../contexts/AuthProvider/AuthProvider";
import { api } from "../../api/api";
import { useDispatchWrapper } from "../../hooks";
import { showMessage } from "../../redux/messages/messagesActions";
import { LoadState } from "../../constants/enums";
import { LinearProgress } from "@mui/material";

interface IRolesContext {
	roles: ISecurityGroup[];
}

export interface IPremissions extends ISecurityGroup {
	view_access: number;
	edit_access: number;
	bulk_export: number;
	bulk_import: number;
	send_mail: number;
	send_sms: number;
	send_whatsapp: number;
	send_call: number;
}

export const RolesContext = React.createContext<IRolesContext>({
	roles: [],
});

export const useRoles = () => React.useContext(RolesContext);

export const RolesProvider: React.FC<{ children: React.ReactNode }> = (
	props
) => {
	const [roles, setRoles] = React.useState<ISecurityGroup[]>([]);
	const { userInfo, isLogin } = useAuth();
	const [loading, setLoading] = React.useState<LoadState>(LoadState.Loaded);
	const dispatch = useDispatchWrapper();

	const fetchRoles = async () => {
		try {
			setLoading(LoadState.InProgress);

			let response;
			if (userInfo.isExternalLogin === true) {
				response = await api.get(
					"/externalSupplier/get-user?status=ACTIVE&user_uuid=" +
						userInfo.user.user_uuid
				);
			} else {
				response = await api.get(
					"/user/get-user?status=ACTIVE&user_uuid=" + userInfo.user.user_uuid
				);
			}

			if (response.data.data.length > 0) {
				setRoles(response.data.data[0].module_security);
				setLoading(LoadState.Loaded);
			}
		} catch (err: any) {
			setLoading(LoadState.Failed);
			dispatch(
				showMessage({
					type: "error",
					message: err.response.data.message,
					displayAs: "snackbar",
				})
			);
		}
	};

	React.useEffect(() => {
		if (isLogin) {
			fetchRoles();
		}
	}, [isLogin]);

	if (!isLogin) {
		return <>{props.children}</>;
	}

	if (loading !== LoadState.Loaded) {
		return <LinearProgress />;
	}

	return (
		<RolesContext.Provider value={{ roles }}>
			{props.children}
		</RolesContext.Provider>
	);
};

// const roles: ISecurityGroup[] = [
//   {
//     module_id: 1,
//     module_name: "Lead",
//     submodule_name: "Leads",
//     role_name: "",
//     role_id: 0,
//     view_access: 1,
//     edit_access: 1,
//     bulk_import: 0,
//     send_sms: 0,
//     send_mail: 0,
//     send_whatsapp: 0,
//     send_call: 0,
//     bulk_export: 0,
//   },
//   {
//     module_id: 2,
//     module_name: "Opportunity",
//     submodule_name: "Opportunity",
//     role_name: "",
//     role_id: 0,
//     view_access: 1,
//     edit_access: 1,
//     bulk_import: 0,
//     send_sms: 0,
//     send_mail: 0,
//     send_whatsapp: 0,
//     send_call: 0,
//     bulk_export: 0,
//   },
//   {
//     module_id: 3,
//     module_name: "Policy",
//     submodule_name: "Business Policy",
//     role_name: "",
//     role_id: 0,
//     view_access: 1,
//     edit_access: 1,
//     bulk_import: 0,
//     send_sms: 0,
//     send_mail: 0,
//     send_whatsapp: 0,
//     send_call: 0,
//     bulk_export: 0,
//   },
//   {
//     module_id: 4,
//     module_name: "Brokerage",
//     submodule_name: "Brokerage",
//     role_name: "",
//     role_id: 0,
//     view_access: 1,
//     edit_access: 1,
//     bulk_import: 0,
//     send_sms: 0,
//     send_mail: 0,
//     send_whatsapp: 0,
//     send_call: 0,

//     bulk_export: 0,
//   },
//   {
//     module_id: 5,
//     module_name: "Brokerage",
//     submodule_name: "Insurer",
//     role_name: "",
//     role_id: 0,
//     view_access: 1,
//     edit_access: 1,
//     bulk_import: 0,
//     send_sms: 0,
//     send_mail: 0,
//     send_whatsapp: 0,
//     send_call: 0,

//     bulk_export: 0,
//   },
//   {
//     module_id: 6,
//     module_name: "Brokerage",
//     submodule_name: "Insurer Contacts",
//     role_name: "",
//     role_id: 0,
//     view_access: 1,
//     edit_access: 1,
//     bulk_import: 0,
//     send_sms: 0,
//     send_mail: 0,
//     send_whatsapp: 0,
//     send_call: 0,

//     bulk_export: 0,
//   },
//   {
//     module_id: 7,
//     module_name: "Brokerage",
//     submodule_name: "Underwriters",
//     role_name: "",
//     role_id: 0,
//     view_access: 0,
//     edit_access: 1,
//     bulk_import: 1,
//     send_sms: 0,
//     send_mail: 0,
//     send_whatsapp: 0,
//     send_call: 0,

//     bulk_export: 0,
//   },
//   {
//     module_id: 8,
//     module_name: "Brokerage",
//     submodule_name: "Broker Codes",
//     role_name: "",
//     role_id: 0,
//     view_access: 0,
//     edit_access: 1,
//     bulk_import: 1,
//     send_sms: 0,
//     send_mail: 0,
//     send_whatsapp: 0,
//     send_call: 0,

//     bulk_export: 0,
//   },
//   {
//     module_id: 9,
//     module_name: "Brokerage",
//     submodule_name: "Branch Offices",
//     role_name: "",
//     role_id: 0,
//     view_access: 0,
//     edit_access: 1,
//     bulk_import: 1,
//     send_sms: 0,
//     send_mail: 0,
//     send_whatsapp: 0,
//     send_call: 0,

//     bulk_export: 0,
//   },
//   {
//     module_id: 10,
//     module_name: "Brokerage",
//     submodule_name: "Interested Parties",
//     role_name: "",
//     role_id: 0,
//     view_access: 0,
//     edit_access: 1,
//     bulk_import: 1,
//     send_sms: 0,
//     send_mail: 0,
//     send_whatsapp: 0,
//     send_call: 0,

//     bulk_export: 0,
//   },
//   {
//     module_id: 11,
//     module_name: "Brokerage",
//     submodule_name: "Additional Interests",
//     role_name: "",
//     role_id: 0,
//     view_access: 0,
//     edit_access: 1,
//     bulk_import: 1,
//     send_sms: 0,
//     send_mail: 0,
//     send_whatsapp: 0,
//     send_call: 0,

//     bulk_export: 0,
//   },
//   {
//     module_id: 12,
//     module_name: "Policies",
//     submodule_name: "All Policies",
//     role_name: "",
//     role_id: 0,
//     view_access: 0,
//     edit_access: 1,
//     bulk_import: 1,
//     send_sms: 0,
//     send_mail: 0,
//     send_whatsapp: 0,
//     send_call: 0,

//     bulk_export: 0,
//   },
//   {
//     module_id: 13,
//     module_name: "Policies",
//     submodule_name: "Business",
//     role_name: "",
//     role_id: 0,
//     view_access: 0,
//     edit_access: 1,
//     bulk_import: 1,
//     send_sms: 0,
//     send_mail: 0,
//     send_whatsapp: 0,
//     send_call: 0,

//     bulk_export: 0,
//   },
//   {
//     module_id: 14,
//     module_name: "Policies",
//     submodule_name: "Commercial Auto",
//     role_name: "",
//     role_id: 0,
//     view_access: 0,
//     edit_access: 1,
//     bulk_import: 1,
//     send_sms: 0,
//     send_mail: 0,
//     send_whatsapp: 0,
//     send_call: 0,

//     bulk_export: 0,
//   },
//   {
//     module_id: 15,
//     module_name: "Policies",
//     submodule_name: "Automobile",
//     role_name: "",
//     role_id: 0,
//     view_access: 0,
//     edit_access: 1,
//     bulk_import: 1,
//     send_sms: 0,
//     send_mail: 0,
//     send_whatsapp: 0,
//     send_call: 0,

//     bulk_export: 0,
//   },
//   {
//     module_id: 16,
//     module_name: "Policies",
//     submodule_name: "Habitational",
//     role_name: "",
//     role_id: 0,
//     view_access: 0,
//     edit_access: 1,
//     bulk_import: 1,
//     send_sms: 0,
//     send_mail: 0,
//     send_whatsapp: 0,
//     send_call: 0,

//     bulk_export: 0,
//   },
//   {
//     module_id: 17,
//     module_name: "Policies",
//     submodule_name: "Life & Fincancial",
//     role_name: "",
//     role_id: 0,
//     view_access: 0,
//     edit_access: 1,
//     bulk_import: 1,
//     send_sms: 0,
//     send_mail: 0,
//     send_whatsapp: 0,
//     send_call: 0,

//     bulk_export: 0,
//   },
//   {
//     module_id: 18,
//     module_name: "Tasks",
//     submodule_name: "All Task",
//     role_name: "",
//     role_id: 0,
//     view_access: 1,
//     edit_access: 1,
//     bulk_import: 0,
//     send_sms: 0,
//     send_mail: 0,
//     send_whatsapp: 0,
//     send_call: 0,

//     bulk_export: 0,
//   },
//   {
//     module_id: 19,
//     module_name: "Tasks",
//     submodule_name: "Insurance Reports",
//     role_name: "",
//     role_id: 0,
//     view_access: 1,
//     edit_access: 1,
//     bulk_import: 0,
//     send_sms: 0,
//     send_mail: 0,
//     send_whatsapp: 0,
//     send_call: 0,

//     bulk_export: 0,
//   },
//   {
//     module_id: 20,
//     module_name: "Tasks",
//     submodule_name: "Taskboard",
//     role_name: "",
//     role_id: 0,
//     view_access: 0,
//     edit_access: 1,
//     bulk_import: 1,
//     send_sms: 0,
//     send_mail: 0,
//     send_whatsapp: 0,
//     send_call: 0,

//     bulk_export: 0,
//   },
//   {
//     module_id: 21,
//     module_name: "Tasks",
//     submodule_name: "Calender",
//     role_name: "",
//     role_id: 0,
//     view_access: 0,
//     edit_access: 1,
//     bulk_import: 1,
//     send_sms: 0,
//     send_mail: 0,
//     send_whatsapp: 0,
//     send_call: 0,

//     bulk_export: 0,
//   },
//   {
//     module_id: 22,
//     module_name: "Tasks",
//     submodule_name: "Renewals",
//     role_name: "",
//     role_id: 0,
//     view_access: 0,
//     edit_access: 1,
//     bulk_import: 1,
//     send_sms: 0,
//     send_mail: 0,
//     send_whatsapp: 0,
//     send_call: 0,

//     bulk_export: 0,
//   },
//   {
//     module_id: 23,
//     module_name: "Tasks",
//     submodule_name: "Binders",
//     role_name: "",
//     role_id: 0,
//     view_access: 0,
//     edit_access: 1,
//     bulk_import: 1,
//     send_sms: 0,
//     send_mail: 0,
//     send_whatsapp: 0,
//     send_call: 0,

//     bulk_export: 0,
//   },
//   {
//     module_id: 24,
//     module_name: "Tasks",
//     submodule_name: "Marketing",
//     role_name: "",
//     role_id: 0,
//     view_access: 0,
//     edit_access: 1,
//     bulk_import: 1,
//     send_sms: 0,
//     send_mail: 0,
//     send_whatsapp: 0,
//     send_call: 0,

//     bulk_export: 0,
//   },
//   {
//     module_id: 25,
//     module_name: "Users",
//     submodule_name: "Users",
//     role_name: "",
//     role_id: 0,
//     view_access: 0,
//     edit_access: 1,
//     bulk_import: 1,
//     send_sms: 0,
//     send_mail: 0,
//     send_whatsapp: 0,
//     send_call: 0,

//     bulk_export: 0,
//   },
//   {
//     module_id: 26,
//     module_name: "Security",
//     submodule_name: "Security",
//     role_name: "",
//     role_id: 0,
//     view_access: 0,
//     edit_access: 1,
//     bulk_import: 1,
//     send_sms: 0,
//     send_mail: 0,
//     send_whatsapp: 0,
//     send_call: 0,

//     bulk_export: 0,
//   },
//   {
//     module_id: 27,
//     module_name: "Templates",
//     submodule_name: "Templates",
//     role_name: "",
//     role_id: 0,
//     view_access: 0,
//     edit_access: 1,
//     bulk_import: 1,
//     send_sms: 0,
//     send_mail: 0,
//     send_whatsapp: 0,
//     send_call: 0,

//     bulk_export: 0,
//   },
//   {
//     module_id: 28,
//     module_name: "HR",
//     submodule_name: "HR",
//     role_name: "",
//     role_id: 0,
//     view_access: 0,
//     edit_access: 1,
//     bulk_import: 1,
//     send_sms: 0,
//     send_mail: 0,
//     send_whatsapp: 0,
//     send_call: 0,

//     bulk_export: 0,
//   },
// ];
