import { lazy } from "react";
import Loadable from "../../layout/fullLayout/loadable/loadable";
import { MODULE_IDS } from "../../constants/enums";
import { AuthorizedRoute } from "../../router/AuthorizedRoute";

import { APP_DISCREPANCY_ROUTE } from "./discrepancy.constants";

const DiscrepancyListTable = Loadable(
	lazy(() =>
		import("./discrepancyList").then(({ DiscrepancyListTable }) => ({
			default: DiscrepancyListTable,
		}))
	)
);

export const discrepancyRoutes = [
	{
		path: APP_DISCREPANCY_ROUTE,
		exact: true,
		element: (
			<AuthorizedRoute moduleIds={[MODULE_IDS.ORDER]}>
				<DiscrepancyListTable />
			</AuthorizedRoute>
		),
	},
];
