import { lazy } from "react";
import Loadable from "../../layout/fullLayout/loadable/loadable";
import { AuthorizedRoute } from "../../router/AuthorizedRoute";

import { APP_ICT_ROUTE, APP_ICT_IMPORTS_ROUTE } from "./Ict.constants";
import { ManageSingleFileUploadWithAI } from "../FileUploadWithAI/ManageSingleFileUploadWithAI";
import { MODULE_IDS } from "../../constants/enums";

const ManageICTFrom = Loadable(
	lazy(() =>
		import("./ManageICTFrom").then(({ ManageICTFrom }) => ({
			default: ManageICTFrom,
		}))
	)
);
const IctList = Loadable(
	lazy(() =>
		import("./IctList").then(({ IctList }) => ({
			default: IctList,
		}))
	)
);
// const ImportExcelLineSheet = Loadable(
// 	lazy(() =>
// 		import("./ExcelImports/ImportExcelLineSheet").then(
// 			({ ImportExcelLineSheet }) => ({
// 				default: ImportExcelLineSheet,
// 			})
// 		)
// 	)
// );

export const IctFileUploadWithAIRouter = [
	{
		path: `${APP_ICT_ROUTE}`,
		exact: true,
		element: (
			<AuthorizedRoute
				allowFullAccess
				moduleIds={[MODULE_IDS.ICT]}
			>
				<IctList />
			</AuthorizedRoute>
		),
	},
	{
		path: `${APP_ICT_ROUTE}/manage`,
		exact: true,
		element: (
			<AuthorizedRoute
				allowFullAccess
				moduleIds={[MODULE_IDS.ICT]}
			>
				<ManageICTFrom />
			</AuthorizedRoute>
		),
	},
	{
		path: `${APP_ICT_ROUTE}/manage/:uuid`,
		exact: true,
		element: (
			<AuthorizedRoute
				allowFullAccess
				moduleIds={[MODULE_IDS.ICT]}
			>
				<ManageICTFrom />
			</AuthorizedRoute>
		),
	},
];
