import { LoadState } from "../../constants/enums";
import { IOrder, IOrderItem, IOrdersState } from "./orders.types";

export const defaultOrderItem: IOrderItem = {
	orders_uuid: "",
	order_item_uuid: "",
	product_uuid: "",
	product_vpn: null,
	primary_image_url: null,
	preview_url: null,
	barcode: null,
	style: null,
	color: null,
	fabric: null,
	size: null,
	description: null,
	category: null,
	class: null,
	subclass: null,
	season: null,
	line: null,
	subline: null,
	gender: null,
	brand: null,
	unit_cost: null,
	rsp: null,
	quantity: null,
	currency: null,
	additional_property: {},
	status: null,
	created_by_uuid: "",
	created_by_name: "",
	modified_by_uuid: "",
	modified_by_name: "",
	create_ts: "",
	supplier: "",
	supplier_code: "",
	zone: "",
	area: "",
	season_uda: "",
	recurrence: "",
	po_number: "",
	delivery_start_date: null,
	delivery_end_date: null,
	taxomany: "",
	usage_specificity: "",
	country_manufacture: "",
	country_shipment: "",
	discount: 0.0,
	mark_up: 0.0,
	hts_code: "",
	made_of: "",
};
export const defaultOrder: IOrder = {
	orders_uuid: "",
	supplier_uuid: "",
	supplier_name: "",
	supplier_email: "",
	total_amount: null,
	order_item_list: [],
	shipping_address_line_1: null,
	shipping_address_line_2: null,
	shipping_city: null,
	shipping_state: null,
	shipping_pincode: null,
	shipping_country: null,
	billing_address_line_1: null,
	billing_address_line_2: null,
	billing_city: null,
	billing_state: null,
	billing_pincode: null,
	billing_country: null,
	status: "DRAFT",
	order_no: "",
};

export const defaultOrdersState: IOrdersState = {
	orders_list: {
		loading: LoadState.NotLoaded,
		data: [],
		total_count: 0,
		error: null,
	},
	single_order: {
		loading: LoadState.NotLoaded,
		data: defaultOrder,
		error: null,
	},
	orders_approvals_list: {
		loading: LoadState.NotLoaded,
		data: [],
		total_count: 0,
		error: null,
	},
	single_order_discrepancy: {
		loading: LoadState.NotLoaded,
		data: {
			...defaultOrder,
			order_discrepancies_data: [],
		},
		error: null,
	},
};
