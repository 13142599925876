import { lazy } from "react";
import Loadable from "../../../layout/fullLayout/loadable/loadable";
import { MODULE_IDS } from "../../../constants/enums";
import { AuthorizedRoute } from "../../../router/AuthorizedRoute";
import { APP_SUPPLIERS_ROUTE } from "./Suppliers.constants";

const SuppliersListTable = Loadable(
	lazy(() =>
		import("./SuppliersListTable").then(({ SuppliersListTable }) => ({
			default: SuppliersListTable,
		}))
	)
);

const ManageSingleSupplier = Loadable(
	lazy(() =>
		import("./ManageSingleSupplier").then(({ ManageSingleSupplier }) => ({
			default: ManageSingleSupplier,
		}))
	)
);

export const supplierRoutes = [
	{
		path: APP_SUPPLIERS_ROUTE,
		exact: true,
		element: (
			<AuthorizedRoute moduleIds={[MODULE_IDS.USERS]}>
				<SuppliersListTable />
			</AuthorizedRoute>
		),
	},
	{
		path: `${APP_SUPPLIERS_ROUTE}/manage`,
		exact: true,
		element: (
			<AuthorizedRoute moduleIds={[MODULE_IDS.USERS]}>
				<ManageSingleSupplier />
			</AuthorizedRoute>
		),
	},
	{
		path: `${APP_SUPPLIERS_ROUTE}/manage/:uuid`,
		exact: true,
		element: (
			<AuthorizedRoute moduleIds={[MODULE_IDS.USERS]}>
				<ManageSingleSupplier />
			</AuthorizedRoute>
		),
	},
];
