import { lazy } from "react";
import Loadable from "../../layout/fullLayout/loadable/loadable";
import { MODULE_IDS } from "../../constants/enums";
import { AuthorizedRoute } from "../../router/AuthorizedRoute";
import { APP_PRODUCT_VALIDATION_ROUTE } from "./ProductValidation.constants";

const ProductValidationTable = Loadable(
	lazy(() =>
		import("./ProductValidationTable").then(({ ProductValidationTable }) => ({
			default: ProductValidationTable,
		}))
	)
);
export const productValidationRoutes = [
	{
		path: `${APP_PRODUCT_VALIDATION_ROUTE}/manage/:uuid`,
		exact: true,
		element: (
			<AuthorizedRoute moduleIds={[MODULE_IDS.PRODUCT]}>
				<ProductValidationTable />
			</AuthorizedRoute>
		),
	},
	//   {
	//     path: `${PRODUCTS_ROUTE}/manage`,
	//     exact: true,
	//     element: (
	//       <AuthorizedRoute allowFullAccess moduleIds={[MODULE_IDS.PRODUCTS]}>
	//         <ManageSingleProduct />
	//       </AuthorizedRoute>
	//     ),
	//   },
	//   {
	//     path: `${PRODUCTS_ROUTE}/manage/:uuid`,
	//     exact: true,
	//     element: (
	//       <AuthorizedRoute allowFullAccess moduleIds={[MODULE_IDS.PRODUCTS]}>
	//         <ManageSingleProduct />
	//       </AuthorizedRoute>
	//     ),
	//   },
];
