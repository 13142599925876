import { LoadState } from "../../constants/enums";
import { IUser, IUsersState } from "./userProfile.types";

export const initialUser: IUser = {
	first_name: "",
	personal_email: "",
	role_uuid: "",
	user_uuid: "",
	last_name: null,
	job_title: null,
	user_type: null,
	assigned_phone_number: null,
	shared_email: null,
	mobile: null,
	home_phone: null,
	linkedin_profile: null,
	hire_date: null,
	last_day_at_work: null,
	branch: null,
	department: null,
	fax: null,
	date_of_birth: null,
	mother_maiden_name: null,
	photo: null,
	signature: null,
	street_address: null,
	unit_or_suite: null,
	city: null,
	province_or_state: null,
	postal_code: null,
	country: null,
	languages_known: [],
	documents: null,
	manager_uuid: null,
	branch_name: "",
	branch_uuid: "",
	status: "ACTIVE",
	is_external_user: false,
};

export const initialUserProfileState: IUsersState = {
	users: {
		data: [],
		totalNumberOfRecords: 0,
		loading: LoadState.NotLoaded,
		user: initialUser,
		userLoading: LoadState.NotLoaded,
		error: null,
	},
};
